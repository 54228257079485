import React, { useRef } from 'react'
import SliderArrows from './slider-arrows'

const Slider = ({ children, className }) => {
  const ref = useRef()

  return (
    <section className={`slider${className ? ` ${className}` : ''}`}>
      <div className="slider--content" ref={ref}>
        <div>{children}</div>
      </div>
      <SliderArrows sliderRef={ref} />
    </section>
  )
}

export default Slider
