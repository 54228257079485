import React, { useEffect, useState } from 'react'
import cx from 'classnames'

import Icon from './icon'

Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2
  if (t < 1) return (c / 2) * t * t + b
  t--
  return (-c / 2) * (t * (t - 2) - 1) + b
}

const SliderArrows = ({ sliderRef }) => {
  const [showArrows, setShowArrows] = useState(false)
  const [showLeftArrow, setShowLeftArrow] = useState(true)
  const [showRightArrow, setShowRightArrow] = useState(true)

  const scrollTo = (element, to, duration) => {
    var start = element.scrollLeft,
      change = to - start,
      currentTime = 0,
      increment = 20

    var animateScroll = function () {
      currentTime += increment
      const smoothScrollSupported = CSS.supports('( scroll-behavior: smooth )')
      var val = smoothScrollSupported
        ? to
        : Math.easeInOutQuad(currentTime, start, change, duration)
      element.scrollLeft = val
      if (!smoothScrollSupported && currentTime < duration) {
        setTimeout(animateScroll, increment)
      } else {
        element.scrollLeft = to
      }
    }
    animateScroll()
  }

  const handleClick = (direction) => {
    const { left: sliderLeft } =
      sliderRef.current.parentNode.getBoundingClientRect()

    var next, prev
    if (direction > 0) {
      next = Array.from(sliderRef.current.firstChild.children).find(
        (node) => node.getBoundingClientRect().left > sliderLeft
      )
    } else {
      prev = Array.from(sliderRef.current.firstChild.children)
        .reverse()
        .find((node) => node.getBoundingClientRect().left < sliderLeft)
    }

    if (next || prev)
      scrollTo(sliderRef.current, (next || prev).offsetLeft, 650)
  }

  const handleScroll = () => {
    const { scrollWidth, scrollLeft } = sliderRef.current
    const { width } = sliderRef.current.getBoundingClientRect()
    setShowLeftArrow(scrollLeft > 0)
    setShowRightArrow(width + scrollLeft < scrollWidth)
  }

  useEffect(() => {
    const { current: slider } = sliderRef || {}
    if (slider) {
      setShowArrows(slider.scrollWidth > slider.getBoundingClientRect().width)
      slider.addEventListener('scroll', handleScroll)
    }
    handleScroll()
  }, [sliderRef.current])

  if (!showArrows) return null
  return (
    <div className="slider--arrows">
      <button
        className={cx('slider--arrows--arrow is-left btn is-primary', {
          'is-visible': showLeftArrow,
        })}
        onClick={() => handleClick(-1)}
      >
        <Icon name="ArrowLeft" color="white" />
      </button>
      <button
        className={cx('slider--arrows--arrow is-right btn is-primary', {
          'is-visible': showRightArrow,
        })}
        onClick={() => handleClick(1)}
      >
        <Icon name="ArrowRight" color="white" />
      </button>
    </div>
  )
}

export default SliderArrows
