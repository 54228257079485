import React from 'react'
import cx from 'classnames'

import { useExchangePrice } from '@lib/context'

const ProductPrice = ({
  item,
  prefix,
  comparePrice,
  quantity,
  // if it's old price, we need to apply 'is-old' class
  isOldPrice,
  // if framePickerDiscountPercentage is passed in, we need to apply the discount percentage. framePickerDiscountPercentage should only be passed in when the product is a frame
  framePickerDiscountPercentage,
}) => {
  const exchangePrice = useExchangePrice(framePickerDiscountPercentage)

  return (
    <span className={cx('price', { 'is-old': comparePrice || isOldPrice })}>
      {prefix}
      {exchangePrice({ item, comparePrice, quantity })}
    </span>
  )
}

export default ProductPrice
